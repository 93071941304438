import { createSlice } from '@reduxjs/toolkit';
import spain from '../../assets/images/flags/spain.png';
import germany from '../../assets/images/flags/germany.png';
import england from '../../assets/images/flags/england.png';
import flag from '../../assets/images/flags/spain.png';

const initialState = {
  values: {btn1: [], btn2: '시간순', btn3: '전체'},
  favoriteCards: [{
    unique: 1,
    id: '000',
    flag: spain,
    name: '라리가',
    isActive: true,
    withPlus: true,
    firstScore: 2,
    secondScore: 0,
    firstTeam: 'FC바르셀로나',
    secondTeam: '레알마드리드',
    isFavorite: true,
    type: 'red',
    },
    {
      unique: 2,
      id: '001',
      flag: england,
      name: '프리미어리그',
      isActive: false,
      withPlus: true,
      firstScore: 2,
      secondScore: 0,
      firstTeam: '맨체스터유나이티드',
      secondTeam: '리버풀',
      isFavorite: true,
      type: 'red',
    },
    {
      unique: 3,
      id: '002',
      flag: germany,
      name: '분데스리가',
      isActive: false,
      withPlus: true,
      firstScore: 2,
      secondScore: 0,
      firstTeam: '바이헤른뮌헨',
      secondTeam: '프랑크푸르트',
      isFavorite: true,
      type: 'red',
    },
    {
      unique: 4,
      id: '003',
      flag: spain,
      name: '라리가',
      isActive: true,
      withPlus: true,
      firstScore: 2,
      secondScore: 0,
      firstTeam: 'FC바르셀로나',
      secondTeam: '레알마드리드',
      isFavorite: true,
      type: 'red',
    },],
  nonLiveCard: [{
      unique: 5,
      id: 0,
      flag: spain,
      name: '라리가',
      isActive: true,
      withPlus: true,
      firstTeam: 'FC바르셀로나',
      secondTeam: '레알마드리드',
      isFavorite: true,
      type: 'blue',
    },{
      unique: 6,
      id: 1,
      flag: england,
      name: '프리미어리그',
      isActive: false,
      withPlus: false,
      firstTeam: '맨체스터유나이티드',
      secondTeam: '리버풀',
      isFavorite: true,
      type: 'blue',
    },
    {
      unique: 7,
      id: 2,
      flag: england,
      name: '프리미어리그',
      isActive: true,
      withPlus: true,
      firstTeam: '맨체스터유나이티드',
      secondTeam: '리버풀',
      isFavorite: true,
      type: 'blue',
    }],
  mainHeaderLive: [{
      id: '00',
      flag: flag,
      name: '라리가',
      isActive: true,
      firstScore: '2',
      secondScore: '0',
      firstTeam: 'FC바르셀로나',
      secondTeam: '레알마드리드',
      withPlus: true,
      type: 'red',
    },{
      id: '01',
      flag: england,
      name: '프리미어리그',
      isActive: false,
      firstScore: 2,
      secondScore: 0,
      firstTeam: '맨체스터유나이티드',
      secondTeam: '리버풀',
      withPlus: false,
      type: 'red',
    },{
      id: '02',
      flag: germany,
      name: '분데스리가',
      isActive: false,
      firstScore: '2',
      secondScore: '0',
      firstTeam: '바이헤른뮌헨',
      secondTeam: '프랑크푸르트',
      withPlus: true,
      type: 'red',
    },{
      id: '03',
      flag: spain,
      name: '라리가',
      isActive: false,
      firstScore: '2',
      secondScore: '0',
      firstTeam: 'FC바르셀로나',
      secondTeam: '레알마드리드',
      withPlus: true,
      type: 'red',
    }],
  mainHeaderNonLive: [{
      id: 0,
      flag: flag,
      name: '라리가',
      firstTeam: 'FC바르셀로나',
      secondTeam: '레알마드리드',
      type: 'blue',
    },{
      id: 1,
      flag: england,
      name: '프리미어리그',
      firstTeam: '맨체스터유나이티드',
      secondTeam: '리버풀',
      type: 'blue',
    },{
      id: 2,
      flag: england,
      name: '프리미어리그',
      firstTeam: '맨체스터유나이티드',
      secondTeam: '리버풀',
      type: 'blue',
    }],
  buttonsValue: [{
      content: '5,000',
    },{
      content: '10,000',
    },{
      content: '50,000',
    },{
      content: '100,000',
    },{
      content: '1,000,000',
    }]
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    mainHeader: (state, action) => {
      state.values = action.payload;
    },
  },
});
export const { mainHeader } = userSlice.actions;
export default userSlice.reducer;
export const selectUser = (state) => state.user.value;
