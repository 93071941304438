import React from 'react';
import group from "../../assets/images/imgs/game.png";

function NonLiveBet() {
  return (
    <div className='non-live-bet'>
      <img src={group} alt='' style={{ width: '100%', height: 'auto' }} />
    </div>
  );
}

export default NonLiveBet;
